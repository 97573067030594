<template>
  <div>

    <div v-if=" shippingInformation.own_shipping ">
      <p class="text-warning" > 
        <i class="fa fa-exclamation-triangle mr-1 fa-sm "></i> 
        <strong v-text=" $t('dashboard.order.shipping.warning') "></strong>
      </p>

      <p class="mb-0">
        <span v-text="$t('dashboard.order.externalLabel') + ' '"></span>
        <span v-text="$t('dashboard.order.shipping.ownShipmentMessage')"> </span>
      </p>
    </div>

    <div v-else>
      <div>
        <!-- Mobile -->
        <table class="table table-sm d-lg-none table-striped">
          <thead>
            <tr>
              <th v-text="$t('tables.trackingHistory')"> </th>
            </tr>
          </thead>
          <tbody v-if=" trackingInformation.event_history.length > 0 ">
            <tr v-for=" (eventHis, index) in  trackingInformation.event_history" :key="index + 'mobile'">
              <td>
                <div class="row">
                  <div class="col-5">
                    <p class="mb-0 text-truncate" :title="eventHis.description">
                      <i class="fa " :class=" (index == 0 ) ? 'fa-circle text-success mr-1' : '' "
                        v-if="orderInformation.shipment_status == 'delivered'"></i>
                      <i class="fa " :class=" (index == 0 ) ? 'fa-circle text-info mr-1' : '' " v-else></i>
                      <span v-text="eventHis.description"
                        :class=" (orderInformation.shipment_status == 'delivered' && (index == 0) ) ? ' text-success ' : '' "></span>
                    </p>
                  </div>
                  <div class="col-7">
                    <p class="text-right">
                      <i class="fa fa-calendar-alt mr-1 "></i> <span
                        v-text="fnDate( eventHis.date, CONST.DATE_FORMATS.DD_MM_YYYY + ' - ' + CONST.DATE_FORMATS.HH_MM_SS )  ">
                      </span>
                    </p>
                  </div>
                  <div class="col-12">
                    <p class="text-truncate mb-0 " :title="eventHis.location">
                      <span v-text=" $t('tables.location') + ': ' "></span> <span v-text="eventHis.location">
                      </span>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td>
                <no-data sm :dataMessage="$t('noDataMessages.noInformaton')"
                  v-show=" trackingInformation.event_history.length == 0" :showBtn="false">
                </no-data>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Desktop -->
        <table class="table table-sm d-none d-lg-table table-striped ">
          <thead>
            <tr>
              <th v-text="$t('tables.description')"></th>
              <th v-text="$t('tables.location')"></th>
              <th v-text="$t('tables.date')"> </th>
            </tr>
          </thead>
          <tbody v-if=" trackingInformation.event_history.length > 0 ">
            <tr :class=" (index == 0 ) ? 'text-success' : '' "
              v-for=" (eventHis, index) in  trackingInformation.event_history" :key="index + 'desktop'">
              <td class="text-capitalize">
                <i class="fa " :class=" (index == 0 ) ? 'fa-circle text-success mr-1' : '' "
                  v-if="orderInformation.shipment_status == 'delivered'"></i>
                <i class="fa " :class=" (index == 0 ) ? 'fa-circle text-info mr-1' : '' " v-else></i>
                <span v-text="eventHis.description"
                  :class=" (orderInformation.shipment_status == 'delivered' && (index == 0) ) ? ' text-success ' : '' "></span>
              </td>
              <td>
                <span class="text-capitalize" v-text="eventHis.location"></span>
              </td>
              <td v-text="fnDate( eventHis.date, CONST.DATE_FORMATS.DD_MM_YYYY + ' - ' + CONST.DATE_FORMATS.HH_MM_SS )">
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3">
                <no-data sm :dataMessage="$t('noDataMessages.noInformaton')"
                  v-show=" trackingInformation.event_history.length == 0" :showBtn="false">
                </no-data>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    mapState,
  } from "vuex";
  export default {
    data() {
      return ({})
    },
    computed: {
      ...mapState('order', ['trackingInformation', 'shippingInformation', 'orderInformation']),
    },
    methods: {
      // ...mapActions('order', ['']),
    }
  }
</script>

<style>

</style>